import request from '@/utils/request'

// 业绩领先
export function assetProve(data) {

  return request({
    url: '/api/sale/assetsProof/add',
    method: 'post',
    data
  })
}




